import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import BodyText from '@components/bodyText';
import Contact from '../../components/contact';
import Hero from '../../components/hero';
import Layout from '@components/layout';

const SekkiKredit = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const bodyText = data.contentfulPage.pageContent[1].bodyText;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/sekki', name: 'SEKKI' },
        ]}
        title="Kredit"
      />
       <div className="container">
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
        <BodyText bodyText={bodyText} />
      </div>
      </div>
      <Contact hideFAQ />
    </Layout>
  );
};

export default SekkiKredit;

SekkiKredit.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query SekkiKreditQuery {
    contentfulPage(pageUrl: { eq: "/sekki" }) {
      headLine

      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
